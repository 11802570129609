import { useForm } from 'react-hook-form';
import axios from 'axios';
import styles from './Contact.module.scss';
import { useState } from 'react';

interface FormData {
  name1: string;
  name2: string;
  email: string;
  message: string;
}

const Contact: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onSubmit = async (data: FormData) => {
    try {
      const { name1, name2, email, message } = data;
      const response = await axios.post(
        'https://api.synstunning.com/api/mail',
        {
          name1,
          name2,
          email,
          message,
        },
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setSubmissionStatus('success');
        setIsDisabled(true);
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionStatus('error');
    }
  };

  const onError = () => {
    setSubmissionStatus('error');
  };

  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactWrapper}>
        <div className={styles.infoSection}>
          <h2>Message Us</h2>
          <p>
            Let’s get this conversation started. Tell us a bit about yourself,
            <br /> and we’ll get in touch as soon as we can.
          </p>
        </div>
        <div className={styles.formSection}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className={styles.nameGroup}>
              <div className={styles.inputGroup}>
                <label htmlFor="name1">First Name *</label>
                <input
                  id="name1"
                  {...register('name1', { required: true })}
                  placeholder="First Name"
                  disabled={isDisabled}
                />
                {errors.name1 && (
                  <span className={styles.error}>First name is required</span>
                )}
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="name2">Last Name *</label>
                <input
                  id="name2"
                  {...register('name2', { required: true })}
                  placeholder="Last Name"
                  disabled={isDisabled}
                />
                {errors.name2 && (
                  <span className={styles.error}>Last name is required</span>
                )}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="email">Email Address *</label>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: true,
                  pattern: emailRegex,
                })}
                placeholder="email@example.com"
                disabled={isDisabled}
              />
              {errors.email && (
                <span className={styles.error}>Valid email is required</span>
              )}
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="message">Message *</label>
              <textarea
                id="message"
                rows={8}
                {...register('message', { required: true })}
                disabled={isDisabled}
              />
              {errors.message && (
                <span className={styles.error}>Message is required</span>
              )}
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isDisabled}
            >
              SUBMIT
            </button>

            {submissionStatus === 'error' && (
              <p className={styles.error}>
                * Submission failed. All fields must be filled in.
              </p>
            )}
            {submissionStatus === 'success' && (
              <p className={styles.success}>
                Your submission has been received successfully.
                <br />
                We will review it and get back to you as soon as possible!
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
