import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import ContactMobile from './pages/Contact/ContactMobile';
import useWindowSize from './hook/useWindowSize';

const App: React.FC = () => {
  const { width } = useWindowSize();

  return (
    <div className="app-container">
      <Router>
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/contact"
              element={width < 1100 ? <ContactMobile /> : <Contact />}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
